import React from "react";
import emailjs from "emailjs-com";
import { Button } from '../ButtonElements'
import { InfoContainer, InfoWrapper, InfoRow, Column1, Column2, TextWrapper, TopLine, Heading, Subtitle, BtnWrap, ImgWrap, Img } from './ContactElements'
import Stylesheet from '../Styles.css'

export default function ContactUs({lightBg, id, imgStart, topLine, headLine, lightText, darkText, description, description2, description3,buttonLabel, img, alt, primary, dark, dark2}) {
    function sendEmail(e) {
         e.preventDefault();

        emailjs.sendForm('gmail', 'template_db7lnfy', e.target, 'oJqxt46zmTPxhaVXa')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text); 
        });
        e.preventDefault();
        e.target.reset();        
    }

    return (
        <>            
        <InfoContainer lightBg={lightBg} id={id}>
            <InfoWrapper>
                <TopLine>{topLine}</TopLine>
                <Heading lightText={lightText}>{headLine}</Heading>
                <InfoRow imgStart={imgStart}>
                    <Column1>
                        <TextWrapper>
                            <Subtitle darkText={darkText}>{description}</Subtitle>
                            <Subtitle darkText={darkText}>{description2}</Subtitle> 
                            <Subtitle darkText={darkText}>{description3}</Subtitle>
                            <div className="formcontainer" id="contact"> 
                                <form onSubmit={sendEmail}>
                                    <div>
                                        <input className="input1" type="email" placeholder="Adresse email"    name="email"       required/>
                                    </div>
                                    <div>
                                        <input className="input1" type="text"  placeholder="Sujet"            name="subject"     required/>
                                    </div>
                                    <div>
                                        <select className="input1" name="optionservice" >
                                            <option value="" selected="selected" disabled>Sélectionner le service désiré</option>
                                            <option value="GESTION DE DOCUMENT">GESTION DE DOCUMENT</option>
                                            <option value="GESTION DE PROGRAMME">GESTION DE PROGRAMME</option>
                                            <option value="GESTION DE PC">GESTION DE PC</option>
                                            <option value="SITE WEB">SITE WEB</option>
                                            <option value="CREATION PROGRAMME">PROGRAMME</option>
                                            <option value="BASE DE DONNEES">BASE DE DONNEES</option>
                                            <option value="MAQUETTE 3D">3D</option>
                                            <option value="GESTION DE VIDEO">GESTION DE VIDEO</option>
                                            <option value="GESTION IMAGE">GESTION IMAGE</option>
                                            <option value="PACK : SIMPLE">PACK : SIMPLE</option>
                                            <option value="PACK : ILLIMTE">PACK : ILLIMTE</option>
                                            <option value="PACK : COMPLET">PACK : COMPLET</option>
                                            <option value="Modification sur image">Modification sur image</option>
                                        </select>
                                    </div>
                                        <textarea type="text"  cols="40" rows="5"  placeholder="Votre demande..."  name="message"   required/>  
                                        <button type="submit" value="Send Message">Envoyer</button>
                                </form>
                            </div>
                        </TextWrapper>
                    </Column1>
                    <Column2>
                        <ImgWrap>
                            <Img src={img} alt={alt}/>
                        </ImgWrap>
                    </Column2> 
                </InfoRow>
                <BtnWrap>
                    <Button to='services' 
                    smooth= {true} duration={500} spy={true} exact='true' offset={-80} 
                    primary={primary ? 1 : 0}
                    dark= {dark ? 1 : 0}
                    dark2= {dark2 ? 1 : 0}
                    >{buttonLabel}</Button>
                </BtnWrap>
            </InfoWrapper>
        </InfoContainer> 
        </>

    )

}