import React, {useState, useEffect} from 'react';
import {FaBars} from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
import { animateScroll as scroll } from 'react-scroll';
import {Nav, NavbarContainer, NavLogo, MobileIcon,
         NavMenu, NavItem, NavLinks, NavBtn, NavBtnLink, LogoWrapper, LogoIcon} from './NavbarElements';
import Icon1 from '../../images/LOGO-no-back-white.png'


const Navbar = ({toggle}) => {  // put it here for toggle Navbar  and down in mobileicon
  const [scrollNav, setScrollNav] = useState(false)

  const changeNav = () => {
    if(window.scrollY >= 80){
      setScrollNav(true)
    }else{
      setScrollNav(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', changeNav)    
  }, [])

  //function used when you click on the big ARB IT LOGO to bring you on the top of the page
  const toggleHome = () => {
    scroll.scrollToTop();
  }

  return (// color here is the color you can change for the icons
    <>
    <IconContext.Provider value={{ color: '#fff'}}> 
        <Nav scrollNav={scrollNav}>
            <NavbarContainer>
                {/* <NavLogo to='/'   onClick={toggleHome}>ARB IT</NavLogo> */}
                <NavLogo to='/'   onClick={toggleHome} src={Icon1}/>                 
                {/* <LogoIcon  to='/' onClick={toggleHome} src={Icon1}/>            */}
                <MobileIcon onClick={toggle}>        
                  <FaBars />
                </MobileIcon>
                <NavMenu>
                  <NavItem>
                    <NavLinks to="about" smooth= {true} duration={500} spy={true} exact='true' offset={-80} 
                    >ARB IT</NavLinks>
                  </NavItem>
                  <NavItem>
                    <NavLinks to="services" //activeClass="active"  no need to put this coz we already put it in NavBarElements NavLinks
                    smooth= {true} duration={500} spy={true} exact='true' offset={-80} 
                    >Services</NavLinks>
                  </NavItem>
                  <NavItem>
                    <NavLinks to="tarif" smooth= {true} duration={500} spy={true} exact='true' offset={-80} 
                    >Tarifs</NavLinks>
                  </NavItem>
                  <NavItem>
                    <NavLinks to="contact" smooth= {true} duration={500} spy={true} exact='true' offset={-80} 
                    >Contacter</NavLinks>
                  </NavItem>
                  {/* <NavItem>
                    <NavLinks to="signup"
                    smooth= {true} duration={500} spy={true} exact='true' offset={-80} 
                    >TEST</NavLinks>
                  </NavItem> */}
                </NavMenu>
                <NavBtn>
                  <NavBtnLink to="contact" smooth= {true} duration={500} spy={true} exact='true' offset={-80} >Commander</NavBtnLink>
                </NavBtn>
            </NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  );
};

export default Navbar;