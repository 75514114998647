import React from 'react'
import { Button } from '../ButtonElements'
import { InfoContainer, InfoWrapper, InfoRow, Column1, Column2, TextWrapper, TopLine, Heading, Subtitle, BtnWrap, ImgWrap, Img } from './InfoElements'

//we put the parameters and use them down here ( it means the ll have the value contained in Data.js)
const InfoSection = ({lightBg, id, imgStart, topLine, headLine, lightText, darkText, description, description2, description3,buttonLabel, img, alt, primary, dark, dark2}) => {
  return (
    <>
        <InfoContainer lightBg={lightBg} id={id}>
            <InfoWrapper>
                <InfoRow imgStart={imgStart}>
                    <Column1>
                    <TextWrapper>
                        <TopLine>{topLine}</TopLine>
                        <Heading lightText={lightText}>{headLine}</Heading>
                        <Subtitle darkText={darkText}>{description}</Subtitle>
                        <Subtitle darkText={darkText}>{description2}</Subtitle>
                        <Subtitle darkText={darkText}>{description3}</Subtitle>                        
                    </TextWrapper>
                    </Column1>
                    <Column2>
                        <ImgWrap>
                            <Img src={img} alt={alt}/>
                        </ImgWrap>
                    </Column2>
                </InfoRow>
                <BtnWrap>
                    <Button to='services' 
                    smooth= {true} duration={500} spy={true} exact='true' offset={-80} 
                    primary={primary ? 1 : 0}
                    dark= {dark ? 1 : 0}
                    dark2= {dark2 ? 1 : 0}
                    >{buttonLabel}</Button>
                </BtnWrap>
            </InfoWrapper>
        </InfoContainer>          
    </>
  )
}

export default InfoSection