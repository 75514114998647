import React from 'react'
import { SidebarContainer, Icon, CloseIcon, SidebarWrapper,
          SidebarMenu, SidebarLink, SideBtnWrap, SidebarRoute } from './SidebarElements'

const Sidebar = ({isOpen, toggle}) => {      // put it here for toggle Sidebar  and down    PUT onclick toggle to be able to click on sidebarlink
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>  
        <Icon onClick={toggle}>
            <CloseIcon />
        </Icon>
        <SidebarWrapper>
          <SidebarMenu> 
            <SidebarLink to="about" onClick={toggle}>ARB IT</SidebarLink> 
            <SidebarLink to="services" onClick={toggle} offset={-80}>Services</SidebarLink>
            <SidebarLink to="tarif" onClick={toggle}    offset={-80}>Tarifs</SidebarLink>
            <SidebarLink to="contact" onClick={toggle}  offset={370}>Commander/Contacter</SidebarLink>
          </SidebarMenu>
          {/* <SideBtnWrap>
            <SidebarRoute to="/signin">Sign In</SidebarRoute>
          </SideBtnWrap> */}
        </SidebarWrapper>
    </SidebarContainer>
  )
}

export default Sidebar