import React from 'react'
import { FooterContainer, FooterWrap, FooterLinksContainer, FooterLinksWrapper, 
         FooterLinkItems, FooterLinkTitle, FooterLink, SocialMedia, SocialMediaWrap,
         SocialLogo,  WebsiteRights, SocialIcons, SocialIconLink } from './FooterElements'  
import { FaInstagram, FaYoutube } from 'react-icons/fa'
import { animateScroll as scroll } from 'react-scroll'
import Icon1 from '../../images/LOGO-no-back-white.png'

const Footer = () => {

//function used when you click on the big ARB IT LOGO to bring you on the top of the page
  const toggleHome = () => {
    scroll.scrollToTop();
    }   

  return (
    <FooterContainer> 
        <FooterWrap>
            <FooterLinksContainer>
                <FooterLinksWrapper>                    
                    <FooterLinkItems>
                        <FooterLinkTitle>Social Media</FooterLinkTitle>    
                        <SocialIconLink href="//www.instagram.com/arb.itservice/" target="blank" aria-label="Instagram">
                            <FooterLink to="">Instagram</FooterLink>  
                        </SocialIconLink> 
                        <SocialIconLink href="//www.youtube.com/channel/UCmn2aTYN1MWR2LtheYOTAGw" target="blank" aria-label="Youtube">
                            <FooterLink to="">Youtube</FooterLink>  
                        </SocialIconLink>       
                        {/* <SocialIconLink href="//mail.google.com/mail" target="blank" aria-label="Gmail">
                            <FooterLink to="">Gmail</FooterLink>  
                        </SocialIconLink>  */}
                        <SocialIconLink >
                            <FooterLink to="">arb.itservice.youwant@gmail.com</FooterLink>  
                        </SocialIconLink>                                                                  
                    </FooterLinkItems>
                </FooterLinksWrapper>
                {/* <FooterLinksWrapper>
                    <FooterLinkItems>
                        <FooterLinkTitle>About us</FooterLinkTitle>    
                        <FooterLink to="/">test</FooterLink>
                        <FooterLink to="/">test</FooterLink>
                        <FooterLink to="/">Test</FooterLink>                                            
                    </FooterLinkItems>
                </FooterLinksWrapper> */}
            </FooterLinksContainer>
            <SocialMedia>
                <SocialMediaWrap>
                    {/* <SocialLogo to="/" onClick={toggleHome}>
                        ARB IT SERVICE
                    </SocialLogo> */}
                    <SocialLogo to="/" onClick={toggleHome} src={Icon1}/>
                    <WebsiteRights>ARB IT SERVICE © {new Date().getFullYear()}
                    . All rights reserved.</WebsiteRights>
                    <SocialIcons>
                        <SocialIconLink href="//www.instagram.com/arb.itservice/" target="blank" aria-label="Instagram">
                            <FaInstagram />
                        </SocialIconLink>
                        <SocialIconLink href="//www.youtube.com/channel/UCmn2aTYN1MWR2LtheYOTAGw" target="blank" aria-label="Youtube">
                            <FaYoutube />
                        </SocialIconLink>
                    </SocialIcons>
                </SocialMediaWrap>
            </SocialMedia>
        </FooterWrap>        
    </FooterContainer> 
  )
}

export default Footer