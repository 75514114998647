import styled from "styled-components";
import { Link as LinkS} from 'react-scroll';         // linkScroll

export const ServicesContainer = styled.div`
    height: 1300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #2c3e57;
    // background: #042c0e;

    @media screen and (max-width: 1000px){
        height: 1900px;
    } 
    
    @media screen and (max-width: 860px){
        height: 3300px;
    }
`

export const ServicesWrapper = styled.div`
    max-width: 1000px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    grid-gap: 30px;
    // padding: 0 50px;

    // quand c'est plus grand que 1000 on a 2 colonnes de logo services
    @media screen and (max-width: 1000px){
        grid-template-columns: 1fr 1fr;
    }
    
    @media screen and (max-width: 860px){
        grid-template-columns: 1fr;
        padding: 0 20px;
    }
`

export const ServicesCard = styled(LinkS)`
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 10px;
    max-height: 340px;
    padding: 20px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.2);
    transition: all 0.2 ease-in-out;
    min-height: 320px;
    min-width: 300px; 

    &:hover {
        transform: scale(1.05);
        transition: all 0.2s ease-in-out;
        cursor: pointer;
        color: #01bf71; 

        box-shadow:
        0 0 10px 10px #01bf71;         /* inner white */
        0 0 10px 10px #01bf71,      /* outer white */
    }
`

export const ServicesIcon = styled.img`
    height: 160px;
    width: 160px;
    margin-bottom: 10px;
`

export const ServicesH1 = styled.h1`
    font-size: 2.5rem;
    color: #fff;
    margin-bottom: 64px;

    @media screen and (max-width: 480px){
        font-size: 2rem;
    }
`

export const ServicesH2 = styled.h2`
    font-size: 1rem;
    margin-bottom: 10px;
`

export const ServicesP = styled.p`
    font-size: 1rem;
    text-align: center;
    
`
