import React from 'react'
import Icon1 from '../../images/soft.png'
import Icon2 from '../../images/site3.png'
import Icon3 from '../../images/sql.png'
import Icon4 from '../../images/ps.png'
import Icon5 from '../../images/pr.png'
import Icon6 from '../../images/blender.png'
import Icon7 from '../../images/repair.png'
import Icon8 from '../../images/conf.png'
import Icon9 from '../../images/word.png'
import { ServicesContainer, ServicesH1, ServicesWrapper, ServicesCard, ServicesIcon, ServicesH2, ServicesP} from './ServicesElements'

const Services = () => {
  return (
    <>
    <ServicesContainer id="services">   
        <ServicesH1>Nos Services</ServicesH1>  
        <ServicesWrapper>            
            <ServicesCard to="p" smooth= {true} duration={500} spy={true} exact='true' offset={-100}>
                <ServicesIcon src={Icon1}/>
                <ServicesH2>Programme</ServicesH2>
                <ServicesP>Développement d'application .NET C# WinForms.</ServicesP>
            </ServicesCard>
            <ServicesCard to="sw" smooth= {true} duration={500} spy={true} exact='true' offset={-100}>
                <ServicesIcon src={Icon2}/>
                <ServicesH2>Sites Web</ServicesH2>
                <ServicesP>Développement de site web sur mesure.</ServicesP>
            </ServicesCard>
            <ServicesCard to="db" smooth= {true} duration={500} spy={true} exact='true' offset={-100}>
                <ServicesIcon  src={Icon3}/>
                <ServicesH2>Base de données</ServicesH2>
                <ServicesP>Mise en place de la logique, structure et de base de données.</ServicesP>
            </ServicesCard>
            <ServicesCard to="gv" smooth= {true} duration={500} spy={true} exact='true' offset={-100}>
                <ServicesIcon  src={Icon5}/>
                <ServicesH2>Gestion de vidéo</ServicesH2>
                <ServicesP>Retouche sur vidéo, filtre, effet ...</ServicesP>
            </ServicesCard>
            <ServicesCard to="gi" smooth= {true} duration={500} spy={true} exact='true' offset={-100}>
                <ServicesIcon  src={Icon4}/>
                <ServicesH2>Gestion de image</ServicesH2>
                <ServicesP>Développement de logo, photo de profile, carte de visite, signature mail, retouche sur image.</ServicesP>
            </ServicesCard>
            <ServicesCard to="m" smooth= {true} duration={500} spy={true} exact='true' offset={-100}>
                <ServicesIcon  src={Icon6}/>
                <ServicesH2>Développement 3D</ServicesH2> 
                <ServicesP>Développement d'objet, maison intérieur, extérieur.</ServicesP>
            </ServicesCard>
            <ServicesCard to="gpc" smooth= {true} duration={500} spy={true} exact='true' offset={-100}>
                <ServicesIcon  src={Icon7}/>
                <ServicesH2>Gestion de PC</ServicesH2>
                <ServicesP>Réparation, montage et configuration de pc.</ServicesP>
            </ServicesCard>
            <ServicesCard to="gd" smooth= {true} duration={500} spy={true} exact='true' offset={-100}>
                <ServicesIcon  src={Icon9}/>
                <ServicesH2>Gestion de document</ServicesH2>
                <ServicesP>Création de tous genre de document word, power point, excel.</ServicesP>
            </ServicesCard>
            <ServicesCard to="gp" smooth= {true} duration={500} spy={true} exact='true' offset={-100}>
                <ServicesIcon  src={Icon8}/>
                <ServicesH2>Gestion de programme</ServicesH2>
                <ServicesP>Installation, configuration de programme.</ServicesP>
            </ServicesCard>            
        </ServicesWrapper>        
    </ServicesContainer>        
    </>
  )
}

export default Services