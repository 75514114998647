import React, {useState} from 'react'
import Video from '../../videos/v2.mp4'
import { HeroContainer,HeroBg,VideoBg, HeroContent, HeroH1, HeroP, HeroBtnWrapper, ArrowForward, ArrowRight } from './HeroElements'
import { Button } from '../ButtonElements'


const HeroSection = () => {
    //state for hover  false by default
    const [hover, setHover] = useState(false)

    //function for hover true or false
    const onHover = () => {
        setHover(!hover)
    }

    return (  
        <HeroContainer id="home">
            <HeroBg>
                <VideoBg autoPlay loop muted src={Video} type='video/mp4' />
            </HeroBg>
            <HeroContent>
                <HeroH1>ARB IT SERVICE</HeroH1>
                <HeroH1>You need</HeroH1>
                <HeroP>
                    Nous offrons actuellement nos services IT à prix réduits, ne ratez pas cette opportunité.
                </HeroP>
                <HeroBtnWrapper>
                    <Button to="about" onMouseEnter={onHover} onMouseLeave={onHover} primary='true' dark='true'
                    smooth= {true} duration={500} spy={true} exact='true' offset={-80}>
                        Commencer{ hover ? <ArrowForward /> : <ArrowRight />}
                    </Button>
                </HeroBtnWrapper>
            </HeroContent>
        </HeroContainer>
    )
}

export default HeroSection