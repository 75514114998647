import React, {useState} from 'react';
import SideBar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import HeroSection from '../components/HeroSection';
import InfoSection from '../components/InfoSection';
import InfoTarif from '../components/InfoTarif/index';
import { homeObjOne, objtarif, contact, homeObjThree } from '../components/InfoSection/Data'; // here we make the link with Data
import Services from '../components/Services/Index';
import Footer from '../components/Footer';
import ContactUs from '../components/Contact';



const Home = () => {    
  const [isOpen, setIsOpen] = useState(false) //creating the state  we take the name we used in SidebarElements/ sidebarcontainer

  //function to update the state
  const toggle = () => {
      setIsOpen(!isOpen)  // after we made this function, to make it work we have to pass it as property to SideBar /> and navbar /> 
                          // AND we have to put it in the ACTUAL NAVBAR/index.js and SIDEBAR/index.js
  }


  return (
    <>
        <SideBar isOpen={isOpen} toggle={toggle}/>
        <Navbar toggle={toggle}/>
        <HeroSection />
        <InfoSection {...homeObjOne}/>   
        <Services />
        <InfoTarif />
        <ContactUs {...contact}/>
        <Footer />
    </>
  )
}

export default Home