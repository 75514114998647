//place where we can pass all values to update the design of the site without having to hardcode in properties
// basically all sections are the same we are just changer the content of the sections


//this is the first section
export const homeObjOne = {
    id: 'about',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'ARB IT SERVICE',
    headLine: "NOS OFFRES",
    description: "Notre société propose tout genre de service lié a l'IT.",
    description2: "D'une demande de développement d'application, de site web, à la vérification, montage et réparation de pièces d'ordinateur.",
    description3: "  Demande de document, montage video, modification sur image, maquette 3D ... ",

    buttonLabel: 'Services',
    imgStart: false,
    img: require('../../images/arb-servicesn.png'),
    dark: true,
    primary: true,
    darkText: false
}
//to pass this data to our page we have to put it as property in pages/index.js in infosection
//and the we can display these values in the infosection/index.js adding them as properties

export const objtarif = {
    id: 'tarif',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    headLine: "TARIFS",

    buttonLabel: 'Commandez maintenant',
    imgStart: true,
    img3: require('../../images/sql.png'),

    // img2: require('../../images/t1.png'),
    // img3: require('../../images/t2.png'), 
    alt: 'tarifs',
    dark: true,
    primary: true,
    darkText: true
}

export const contact = {
    id: 'contact',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'ARB IT SERVICE',
    headLine: "Commande/Question",
    description: "Commandez ou demandez plus d'information.",
    description2: "Demandez un devis pour calculer le prix en fonction de votre demande.",

    buttonLabel: 'Services',
    imgStart: true,
    img: require('../../images/order.png'),
    alt: 'tarifs',
    dark: false,
    primary: false,
    darkText: true
}






// export const homeObjThree = {
//     id: 'signup',
//     lightBg: true,
//     lightText: false,
//     lightTextDesc: false,
//     topLine: 'TopLine',
//     headLine: 'Heading Unlimited transaction with zero fees',
//     description: 'description this is another content you can change',

//     buttonLabel: 'Start now',
//     imgStart: false,
//     img: require('../../images/7.svg').default,
//     alt: 'Car',
//     dark: false,
//     primary: false,
//     darkText: true
// }