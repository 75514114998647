import styled from "styled-components";
import { Link as LinkS} from 'react-scroll';         // linkScroll

export const ServicesContainer = styled.div`
    height: 5500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #010606;    

    @media screen and (max-width: 1000px){
        height: 6800px;
    } 
    
    @media screen and (max-width: 860px){ 
        height: 10100px;
    }
`

export const ServicesWrapper = styled.div`
    max-width: 1500px;
    margin: 0 auto;
    display: grid;
    align-items: center;
    grid-gap: 20px;
    // padding: 0 50px;

    // quand c'est plus grand que 1000 on a 2 colonnes de logo services
    @media screen and (max-width: 1000px){
        grid-template-columns: 1fr;
    }
    
    @media screen and (max-width: 860px){
        grid-template-columns: 1fr;
        // padding: 0 20px;
    }
`

export const OptionsWrapper1 = styled.div`
    max-width: 1500px;
    margin: 0 auto;
    display: grid;
    align-items: center;
    grid-gap: 20px;

    border: 1px solid #01bf71;     
    border-radius: 20px;
    padding: 5px;

    // padding: 0 50px;

`

export const OptionsWrapper2 = styled.div`
    max-width: 1500px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    grid-gap: 20px;

    border: 1px solid #01bf71;     
    border-radius: 20px;
    padding: 5px;

    // padding: 0 50px;

    // quand c'est plus grand que 1000 on a 2 colonnes de logo services
    @media screen and (max-width: 1000px){
        grid-template-columns: 1fr 1fr;
    }
    
    @media screen and (max-width: 860px){
        grid-template-columns: 1fr;
        // padding: 0 20px;
    }
`

export const OptionsWrapper3 = styled.div`
    max-width: 1500px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    grid-gap: 20px;

    border: 1px solid #01bf71;     
    border-radius: 20px;
    padding: 5px;

    // padding: 0 50px;
    
    // quand c'est plus grand que 1000 on a 2 colonnes de logo services
    @media screen and (max-width: 1000px){
        grid-template-columns: 1fr 1fr;
    }
    
    @media screen and (max-width: 860px){
        grid-template-columns: 1fr;
        // padding: 0 20px;
    }
`
export const PackageWrapper = styled.div`
    max-width: 1500px;
    margin: 0 auto;
    display: grid;
    // grid-template-columns: 1fr 1fr;
    align-items: center;
    grid-gap: 20px;
    padding: 0 50px;

    // quand c'est plus grand que 1000 on a 2 colonnes de logo services
    @media screen and (max-width: 1000px){
        grid-template-columns: 1fr 1fr;
    }
    
    @media screen and (max-width: 860px){
        grid-template-columns: 1fr;
        padding: 0 20px;
    }
`

export const ServicesCard = styled(LinkS)`
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 20px; 
    min-height: 270px;
    min-width: 300px;    
    max-width: 300px;
    // max-height: 340px;
    // width: 300px;
    // height: 150px;    
    padding: 20px;
    // box-shadow: 0 1px 3px rgba(0,0,0,0.2);
    transition: all 0.2 ease-in-out;
    border: 1px solid #01bf71; 

    &:hover {
        transform: scale(1.05);
        transition: all 0.2s ease-in-out;
        cursor: pointer;
        color: #01bf71;

        box-shadow:
        0 0 10px 10px #01bf71,      /* inner white */
        0 0 10px 10px #01bf71;      /* outer white */
    }
    @media screen and (max-width: 860px){        
    }
`

// package 
export const OptionsWrapper3pack = styled.div`
    max-width: 1000px;
    margin-left:-70px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr; 
    align-items: center;
    grid-gap: 10px;

    // padding: 0 50px;
    
    // quand c'est plus grand que 1000 on a 2 colonnes de logo services
    @media screen and (max-width: 1000px){
        grid-template-columns: 1fr 1fr;
        margin-left:0px;
    }
    
    @media screen and (max-width: 860px){
        grid-template-columns: 1fr;
        margin-left:0px;
        // padding: 0 20px;
    }
`

export const ServicesCardPack = styled(LinkS)`
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 20px; 
    min-height: 410px;
    min-width: 350px;    
    max-width: 300px;    
    padding: 20px;
    transition: all 0.2 ease-in-out;

    box-shadow:
        // inset 0 0 50px 20px #fff,        /* inner gold */
        // inset 20px 0 80px #f0f,          /* inner left magenta short */
        inset -10px 10px 80px 0px #0ff,     /* inner right cyan short */
        inset 50px 0px 180px 0px #f0f,      /* inner left magenta broad */
        inset -0px 0px 300px #0ff,          /* inner right cyan broad */
        0px 0px 50px 0px #fff,              /* outer white */
        -10px 15px 80px 0px #f0f,           /* outer left magenta */
        10px 0px 80px 0px #0ff;             /* outer right cyan */

    &:hover {
        transform: scale(1.05);
        transition: all 0.2s ease-in-out;
        cursor: pointer;
        border: 2px solid #fff; 
        color:  #fcf0c0;

        box-shadow:
            inset 0 0 10px 10px #fcf0c0,/* inner gold */
            inset 20px 0 80px #f0f,     /* inner left magenta short */
            inset -20px 0 80px #0ff,    /* inner right cyan short */
            inset 20px 0 500px #f0f,    /* inner left magenta broad */
            inset 20px 0 200px #0ff,   /* inner right cyan broad */
            0 0 10px 10px #fcf0c0,      /* outer white */
            -10px 0 80px #f0f,          /* outer left magenta */
            10px 0 80px #0ff;           /* outer right cyan */
    }
    
    @media screen and (max-width: 860px){
        min-width: 330px;   
    }
`

export const ServicesIcon = styled.img`
    height: 160px;
    width: 160px;
    // margin-bottom: 10px;
`

export const ServicesH1 = styled.h1`
    font-size: 2.5rem;
    color: #fff;
    margin-bottom: 64px;    

    @media screen and (max-width: 480px){
        font-size: 2rem;
    }
`

export const ServicesH2 = styled.h2`
    color: #01bf71;
    font-size: 1.8rem;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    text-decoration: underline;
    // border: 1px solid #fff;
    // border-radius: 60px; 
    // padding: 7px;

    @media screen and (max-width: 480px){
        font-size: 1.3rem;
    }
`

export const ServicesH3 = styled.h3`
    color: #fff;
    font-size: 1.8rem;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;

    @media screen and (max-width: 480px){
        font-size: 1.3rem;
    }
`

export const ServicesH4 = styled.h4`
    color: #fff;
    font-size: 1.8rem;
    margin-left: auto;
    margin-right: auto;

    @media screen and (max-width: 480px){
        font-size: 1.3rem;
    }
`

export const ServicesH5 = styled.h5`
    font-size: 1rem;
    // margin-bottom: 10px;     
`

export const ServicesH5Prix = styled.h5`
    font-size: 1.5rem;
    color: #01bf71;
    
    transition: all 0.2 ease-in-out;

    &:hover {
        transform: scale(1.3);
        transition: all 0.2s ease-in-out;
        color: #01bf71;   
    }
`

export const ServicesH5packPrix = styled.h5`
    font-size: 1.5rem;
    color: #01bf71;    
    transition: all 0.2 ease-in-out;

    &:hover {
        transform: scale(1.5);
        transition: all 0.2s ease-in-out;
        color: #01bf71;   
        // border-radius: 20px;         
    }
`


export const ServicesH5pack = styled.h5`
    font-size: 1rem;
    // color: #fff;     
`

export const CardTitleWrapper = styled.h5`
    font-size: 1rem;    
    // margin-top: 0px; 
    max-width: 300px;    
    text-align: center; 
`

export const CardTextWrapper = styled.p`
    font-weight: ;
    font-size: 1rem;    
    margin-top: 10px; 
    max-width: 300px; 
    //margin-bottom: 10px;
    // text-align: center;  
`

export const ServicesP = styled.p`
    font-size: 1rem;
    margin-bottom: 8px;       
`








