import styled from 'styled-components';
import { Link as LinkR} from 'react-router-dom';    //linkRouter
import { Link as LinkS} from 'react-scroll';         // linkScroll

//here we are putting CSS on all the brackets that are in the NavBar/index.js  Nav NavbarContainer and NavLogo
export const Nav = styled.nav`
    background: ${({scrollNav}) => (scrollNav ? '#000':'transparent')};
    height: 100px;    
    margin-top: -100px;
    display: flex; 
    justify-content: center;
    align-items: center; 
    font-size: 1rem;
    position: sticky;
    top: 0;
    z-index: 10;  

    @media screen and (max-width: 960px){
        transition: 0.8s all ease;
    }
`;

export const NavbarContainer = styled.div`
    display: flex; 
    justify-content: space-between;
    height: 80px;
    z-index: 1;
    width : 100%;
    padding: 0 24px;
    max-width: 1100px;    
`;

// export const NavLogo = styled(LinkR)`
//     color: #fff;
//     justify-self: flex-start;
//     cursor: pointer; 
//     font-size: 1.5rem;
//     display: flex;
//     align-items: center; 
//     margin-left: 24px;
//     font-weight: bold; 
//     text-decoration: none;
// `;

export const NavLogo = styled.img`
    justify-self: flex-start;
    display: flex;
    margin-left: 24px;
    height: 200px;
    margin-top: -60px;
    cursor: pointer; 


    // justify-self: flex-start;
    // cursor: pointer; 
    // font-size: 1.5rem;
    // // display: flex;
    // align-items: center; 
    // // margin-left: 24px; 
    // position: absolute;
    // top: -95px;
    // height: 350px;
    // border: 2px solid red;


    @media screen and (max-width: 768px){
        // margin-left: 24%;
        margin-left: auto;
        margin-right: auto;
    }   
`;



export const MobileIcon = styled.div`
    display: none;

    @media screen and (max-width: 768px){
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
        color: #fff;
    }    
`;


export const NavMenu = styled.ul`
    display: flex;  
    align-items: center;
    list-style: none;
    text-align: center;  

    @media screen and (max-width: 768px){
        display: none;
    }
`;

export const NavItem = styled.li`
    height: 80px;
`;

export const NavLinks = styled(LinkS)` 
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;

    // this is a shortcut to put active here and not in NavBar/index.js in navLinks
    &.active {
        border-bottom: 3px solid #01bf71;
    }
`;

export const NavBtn = styled.nav`
    display: flex; 
    align-items: center;

    @media screen and (max-width: 768px){
        display:none;
    }
`;

export const NavBtnLink = styled(LinkS)`
    border-radius: 50px;
    background: #01bf71;
    white-space: nowrap; 
    padding: 10px 22px;
    color: #010606;
    font-size: 16px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    

    &:hover {
        transition: all 0.2s ease-in-out;
        background: #fff;
        color: #010606;
    }
`;


export const LogoIcon = styled.img`
    height: 500px;    
    width: 300px;    
    // border: black 4px solid;
`


    

