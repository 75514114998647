import React, {useState} from 'react'
import { Button } from '../ButtonElements'
import Icon1 from '../../images/cvs.png'
import Icon2 from '../../images/cvp.png'
import Icon3 from '../../images/lm.png'

import Icon4 from '../../images/montage.png'
import Icon5 from '../../images/repair.png'
import Icon6 from '../../images/pcR.png'

import Icon7 from '../../images/installP.png'
import Icon8 from '../../images/confP.png'
import Stylesheet from '../Styles.css'
import { InfoTarifContainer, InfoTarifWrapper, InfoTarifRow, Column3, Column4,  TopTarifLine, HeadingTarif, BtnWrapTarif, ImgWrapTarif, ImgTarif } from './TarifElements'
import { ServicesContainer, ServicesH1, ServicesWrapper, OptionsWrapper1, OptionsWrapper2, OptionsWrapper3,  PackageWrapper, ServicesCard, ServicesCardPack, ServicesIcon, ServicesH2, ServicesH3, ServicesH4,ServicesH5, ServicesH5Prix, ServicesH5pack, ServicesH5packPrix, OptionsWrapper3pack, CardTitleWrapper, CardTextWrapper, ServicesP} from './TarifElements'


//we put the parameters and use them down here ( it means the ll have the value contained in Data.js)
const InfoTarif = ({lightBg, id, imgStart, topLine, headLine, lightText, darkText, description, description2, description3,buttonLabel, img2, img3, alt, primary, dark, dark2}) => {
    
    return (
    <>
        <ServicesContainer lightBg={lightBg} id="tarif">  
            <ServicesH1>Tarifs</ServicesH1> 
            <ServicesWrapper>
                <ServicesH2 id="gd">GESTION DE DOCUMENT</ServicesH2>   
                <OptionsWrapper3>  
                        <ServicesCard  to="contact" smooth= {true} duration={500} spy={true} exact='true' offset={-80}>
                            <ServicesIcon src={Icon1}/>
                            <CardTitleWrapper>
                                <ServicesH5>CV SIMPLE</ServicesH5>
                                <ServicesH5Prix>20€-40€</ServicesH5Prix>
                            </CardTitleWrapper>
                        </ServicesCard>
                    <ServicesCard to="contact" smooth= {true} duration={500} spy={true} exact='true' offset={-80}>
                        <ServicesIcon src={Icon2}/>
                        <CardTitleWrapper>
                            <ServicesH5>CV PLUS</ServicesH5> 
                            <ServicesH5Prix>30€-70€</ServicesH5Prix> 
                        </CardTitleWrapper>
                    </ServicesCard>
                    <ServicesCard to="contact" smooth= {true} duration={500} spy={true} exact='true' offset={-80}>
                        <ServicesIcon  src={Icon3}/>
                        <CardTitleWrapper>
                            <ServicesH5>LETTRE DE MOTIVATION</ServicesH5>
                            <ServicesH5Prix>25€</ServicesH5Prix>
                        </CardTitleWrapper>
                    </ServicesCard>
                </OptionsWrapper3>
                <ServicesH2 id="gpc">GESTION DE PC</ServicesH2>
                <OptionsWrapper3>  
                    <ServicesCard to="contact" smooth= {true} duration={500} spy={true} exact='true' offset={-80}>
                        <ServicesIcon  src={Icon4}/>
                        <CardTitleWrapper>
                            <ServicesH5>MONTAGE PC</ServicesH5>
                            <ServicesH5Prix>100€</ServicesH5Prix>
                        </CardTitleWrapper>
                    </ServicesCard>
                    <ServicesCard to="contact" smooth= {true} duration={500} spy={true} exact='true' offset={-80}>
                        <ServicesIcon  src={Icon5}/>
                        <CardTitleWrapper>
                            <ServicesH5>CONFIGURATION PC</ServicesH5>
                            <ServicesH5Prix>50€-100€</ServicesH5Prix>
                        </CardTitleWrapper>
                    </ServicesCard>
                    <ServicesCard to="contact" smooth= {true} duration={500} spy={true} exact='true' offset={-80}>
                        <ServicesIcon  src={Icon6}/>
                        <CardTitleWrapper>
                            <ServicesH5>REPARATION PC</ServicesH5> 
                            <ServicesH5Prix>70€-200€</ServicesH5Prix>
                        </CardTitleWrapper>
                    </ServicesCard>
                </OptionsWrapper3>
                <ServicesH2 id="db">BASE DE DONNEES</ServicesH2>   
                <OptionsWrapper3>    
                    <ServicesCard to="contact" smooth= {true} duration={500} spy={true} exact='true' offset={-80}>
                        <ServicesIcon src={require('../../images/sql.png')}/>
                        <CardTitleWrapper>
                            <ServicesH5>ANALYSE FONCTIONNELLE</ServicesH5>
                            <ServicesH5Prix>20€-40€/h</ServicesH5Prix>
                        </CardTitleWrapper>
                    </ServicesCard>
                    <ServicesCard to="contact" smooth= {true} duration={500} spy={true} exact='true' offset={-80}>
                        <ServicesIcon src={require('../../images/sql.png')}/>
                        <CardTitleWrapper>
                            <ServicesH5>CREATION BASE DE DONNEES</ServicesH5>
                            <ServicesH5Prix>a partir de 200€</ServicesH5Prix>
                        </CardTitleWrapper>
                    </ServicesCard>
                    <ServicesCard to="contact" smooth= {true} duration={500} spy={true} exact='true' offset={-80}>
                        <ServicesIcon  src={require('../../images/sql.png')}/>
                        <CardTitleWrapper>
                            <ServicesH5>MODIFICATION BASE DE DONNEES</ServicesH5>
                            <ServicesH5Prix>a partir de 200€</ServicesH5Prix>
                        </CardTitleWrapper>
                    </ServicesCard>
                </OptionsWrapper3>  
                <ServicesH2 id="gp">GESTION DE PROGRAMME</ServicesH2> 
                <OptionsWrapper2>
                    <ServicesCard to="contact" smooth= {true} duration={500} spy={true} exact='true' offset={-80}>
                        <ServicesIcon  src={Icon7}/>
                        <CardTitleWrapper>
                            <ServicesH5>INSTALLATION DE PROGRAMME</ServicesH5>
                            <ServicesH5Prix>30€</ServicesH5Prix>
                        </CardTitleWrapper>
                    </ServicesCard>
                    <ServicesCard to="contact" smooth= {true} duration={500} spy={true} exact='true' offset={-80}>
                        <ServicesIcon  src={Icon8}/>
                        <CardTitleWrapper>
                            <ServicesH5>CONFIGURATION PROGRAMME</ServicesH5>
                            <ServicesH5Prix>20€</ServicesH5Prix>
                        </CardTitleWrapper>
                    </ServicesCard>                    
                </OptionsWrapper2>
                <ServicesWrapper>  
                    <ServicesH2 id="sw">SITE WEB</ServicesH2>   
                    <OptionsWrapper2>    
                        <ServicesCard to="contact" smooth= {true} duration={500} spy={true} exact='true' offset={-80}>
                            <ServicesIcon src={require('../../images/react.png')}/>
                            <CardTitleWrapper>
                                <ServicesH5>CREATION SITE REACTJS</ServicesH5>
                                <ServicesH5Prix>a partir de 500€</ServicesH5Prix>
                            </CardTitleWrapper>
                        </ServicesCard>
                        <ServicesCard to="contact" smooth= {true} duration={500} spy={true} exact='true' offset={-80}>
                            <ServicesIcon  src={require('../../images/site3.png')}/>
                            <CardTitleWrapper>
                                <ServicesH5>MODIFICATION SITE</ServicesH5>
                                <ServicesH5Prix>a partir de 500€</ServicesH5Prix>
                            </CardTitleWrapper>
                        </ServicesCard>
                    </OptionsWrapper2>
                    <ServicesH2 id="p">PROGRAMME</ServicesH2> 
                    <OptionsWrapper2>
                        <ServicesCard to="contact" smooth= {true} duration={500} spy={true} exact='true' offset={-80}>
                            <ServicesIcon  src={require('../../images/p.png')}/>
                            <CardTitleWrapper>
                                <ServicesH5>CREATION SOFTWARE .NET</ServicesH5>
                                <ServicesH5Prix>a partir de 1000€</ServicesH5Prix>
                            </CardTitleWrapper>
                        </ServicesCard>
                        <ServicesCard to="contact" smooth= {true} duration={500} spy={true} exact='true' offset={-80}>
                            <ServicesIcon  src={require('../../images/p.png')}/>
                            <CardTitleWrapper> 
                                <ServicesH5 name="modifier">MODIFICATION SOFTWARE</ServicesH5>
                                <ServicesH5Prix>a partir de 500€</ServicesH5Prix>
                            </CardTitleWrapper>
                        </ServicesCard>                                      
                    </OptionsWrapper2> 
                    <ServicesH2 id="m" >MAQUETTE 3D</ServicesH2>   
                    <OptionsWrapper2>    
                        <ServicesCard to="contact" smooth= {true} duration={500} spy={true} exact='true' offset={-80}>
                            <ServicesIcon src={require('../../images/bl.png')}/>
                            <CardTitleWrapper>
                                <ServicesH5>OBJET / PRODUIT 3D</ServicesH5>
                                <ServicesH5Prix>a partir de 100€</ServicesH5Prix>
                            </CardTitleWrapper>
                        </ServicesCard>
                        <ServicesCard to="contact" smooth= {true} duration={500} spy={true} exact='true' offset={-80}>
                            <ServicesIcon src={require('../../images/bl.png')}/>
                            <CardTitleWrapper>
                                <ServicesH5>CREATION MAISON</ServicesH5>
                                <ServicesH5Prix>a partir de 500€</ServicesH5Prix>
                            </CardTitleWrapper>
                        </ServicesCard>                    
                    </OptionsWrapper2> 
                </ServicesWrapper>
            </ServicesWrapper>
            <ServicesH2 id="gv"  className='h2'>GESTION DE VIDEO</ServicesH2>
            <OptionsWrapper1>
                <ServicesCard to="contact" smooth= {true} duration={500} spy={true} exact='true' offset={-80}>
                    <ServicesIcon  src={require('../../images/pr2.png')}/>
                    <CardTitleWrapper>
                        <ServicesH5>MODIFICATION VIDEO</ServicesH5>
                        <ServicesH5Prix>a partir de 100€</ServicesH5Prix>
                    </CardTitleWrapper>
                </ServicesCard> 
            </OptionsWrapper1>
            <ServicesH2 id="gi">GESTION D'IMAGE</ServicesH2> 
            <div2>
                <ServicesWrapper>    
                    <ServicesH3>1.LOGO</ServicesH3> 
                    <ServicesH4>PACKAGES PRINCIPAUX</ServicesH4>
                    <OptionsWrapper3pack>
                        <ServicesCardPack to="contact" smooth= {true} duration={500} spy={true} exact='true' offset={-80}>
                            <ServicesIcon  src={require('../../images/packS.png')}/>
                            <CardTitleWrapper>
                                <ServicesH5pack>SIMPLE</ServicesH5pack>
                                <ServicesH5packPrix>70€</ServicesH5packPrix>
                            </CardTitleWrapper>
                            <CardTextWrapper>
                                <ServicesP>1 logo</ServicesP>
                                <ServicesP>1 semaine de modification</ServicesP>
                            </CardTextWrapper>
                        </ServicesCardPack>
                        <ServicesCardPack to="contact" smooth= {true} duration={500} spy={true} exact='true' offset={-80}> 
                            <ServicesIcon  src={require('../../images/packI2.png')}/>
                            <CardTitleWrapper>
                                <ServicesH5pack>ILLIMITE</ServicesH5pack>
                                <ServicesH5packPrix>170€</ServicesH5packPrix>
                            </CardTitleWrapper>
                            <CardTextWrapper>
                                <ServicesP>2 propositions de logo</ServicesP>
                                <ServicesP>Modification illimitée</ServicesP>
                            </CardTextWrapper>
                        </ServicesCardPack> 
                        <ServicesCardPack to="contact" smooth= {true} duration={500} spy={true} exact='true' offset={-80}>
                            <ServicesIcon src={require('../../images/packC.png')}/>
                            <CardTitleWrapper>
                                <ServicesH5pack>COMPLET</ServicesH5pack>
                                <ServicesH5packPrix>250€</ServicesH5packPrix>
                            </CardTitleWrapper>
                            <CardTextWrapper>
                                <ServicesP>1 logo</ServicesP>
                                <ServicesP>Photo de profil</ServicesP>
                                <ServicesP>Signature de mail</ServicesP>
                                <ServicesP>Carte de visite</ServicesP>
                                <ServicesP>Modification illimitée</ServicesP>
                            </CardTextWrapper>
                        </ServicesCardPack> 
                    </OptionsWrapper3pack> 
                    <ServicesH4>HORS PACKAGE</ServicesH4>  
                    <OptionsWrapper2>
                        <ServicesCard to="contact" smooth= {true} duration={500} spy={true} exact='true' offset={-80}>
                            <ServicesIcon  src={require('../../images/hpL2.png')}/>
                                <CardTitleWrapper>
                                <ServicesH5>LOGO</ServicesH5>
                                <ServicesH5Prix>60€</ServicesH5Prix>
                            </CardTitleWrapper>
                        </ServicesCard>
                        <ServicesCard to="contact" smooth= {true} duration={500} spy={true} exact='true' offset={-80}>
                            <ServicesIcon  src={require('../../images/hpP.png')}/>
                                <CardTitleWrapper>
                                <ServicesH5>PHOTO DE PROFIL</ServicesH5>
                                <ServicesH5Prix>40€</ServicesH5Prix>
                            </CardTitleWrapper>
                        </ServicesCard> 
                        <ServicesCard to="contact" smooth= {true} duration={500} spy={true} exact='true' offset={-80}>
                            <ServicesIcon  src={require('../../images/hpS.png')}/>
                                <CardTitleWrapper>
                                <ServicesH5>SIGNATURE DE MAIL</ServicesH5>
                                <ServicesH5Prix>40€</ServicesH5Prix>
                            </CardTitleWrapper>
                        </ServicesCard> 
                        <ServicesCard to="contact" smooth= {true} duration={500} spy={true} exact='true' offset={-80}>
                            <ServicesIcon  src={require('../../images/hpC.png')}/>
                                <CardTitleWrapper>
                                <ServicesH5>CARTE DE VISITE</ServicesH5>
                                <ServicesH5Prix>40€</ServicesH5Prix>
                            </CardTitleWrapper>
                        </ServicesCard> 
                    </OptionsWrapper2>   
                    <ServicesH3>2.MODIFICATION D'IMAGE</ServicesH3>
                    <OptionsWrapper1>          
                        <ServicesCard  to="contact" smooth= {true} duration={500} spy={true} exact='true' offset={-80}>
                            <ServicesIcon  src={require('../../images/ps.png')}/>
                            <CardTitleWrapper>
                                <ServicesH5>MODIFICATION IMAGE</ServicesH5>
                                <ServicesH5Prix>30€-200€</ServicesH5Prix>
                            </CardTitleWrapper>
                        </ServicesCard> 
                    </OptionsWrapper1>       
                </ServicesWrapper>
                <ServicesWrapper>
                    <ServicesH3>3.OPTIONS</ServicesH3> 
                    <OptionsWrapper2>
                        <ServicesCard to="contact" smooth= {true} duration={500} spy={true} exact='true' offset={-80}>
                            <ServicesIcon  src={require('../../images/oI.png')}/>
                            <CardTitleWrapper>
                                <ServicesH5>MODIFICATION ILLIMITEES</ServicesH5>
                                <ServicesH5Prix>100€</ServicesH5Prix>
                            </CardTitleWrapper>
                        </ServicesCard>
                        <ServicesCard to="contact" smooth= {true} duration={500} spy={true} exact='true' offset={-80}>
                            <ServicesIcon  src={require('../../images/oHF.png')}/>
                            <CardTitleWrapper>
                                <ServicesH5>DEMANDE DE MODIFICATION HORS PACK</ServicesH5>
                                <ServicesH5Prix>20€-100€</ServicesH5Prix>
                            </CardTitleWrapper>
                        </ServicesCard>
                        <ServicesCard to="contact" smooth= {true} duration={500} spy={true} exact='true' offset={-80}>
                            <ServicesIcon  src={require('../../images/oT.png')}/>
                            <CardTitleWrapper>
                                <ServicesH5>PLUSIEURS TAILLE DE LOGO</ServicesH5>
                                <ServicesH5Prix>20€</ServicesH5Prix>
                            </CardTitleWrapper>
                        </ServicesCard>
                        <ServicesCard to="contact" smooth= {true} duration={500} spy={true} exact='true' offset={-80}>
                            <ServicesIcon  src={require('../../images/oP.png')}/>
                            <CardTitleWrapper>
                                <ServicesH5>PROPOSITION SUPPLEMENTAIRE</ServicesH5>
                                <ServicesH5Prix>50€</ServicesH5Prix>
                            </CardTitleWrapper>
                        </ServicesCard>                    
                    </OptionsWrapper2> 
                </ServicesWrapper>  
            </div2>
        </ServicesContainer>   
            {/* <InfoTarifContainer lightBg={lightBg} id={id}>  
                <InfoTarifWrapper>
                <TopTarifLine>{topLine}</TopTarifLine>
                <HeadingTarif lightText={lightText}>{headLine}</HeadingTarif>
                <InfoTarifRow imgStart={imgStart}>
                    <Column3>
                        <ImgWrapTarif>
                            <ImgTarif src={img2} alt={alt}/> 
                        </ImgWrapTarif> 
                    </Column3>
                    <Column4>
                        <ImgWrapTarif>
                            <ImgTarif src={img3} alt={alt}/>
                        </ImgWrapTarif>
                    </Column4>                                          
                </InfoTarifRow>
                    <BtnWrapTarif>
                        <Button to='contact' 
                        smooth= {true} duration={500} spy={true} exact='true' offset={-80} 
                        primary={primary ? 1 : 0}
                        dark= {dark ? 1 : 0}
                        dark2= {dark2 ? 1 : 0}
                        >{buttonLabel}</Button>
                    </BtnWrapTarif>
            </InfoTarifWrapper> 
            </InfoTarifContainer>  */}
    </>
  )
}

export default InfoTarif